@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Quicksand, sans-serif;
  font-weight: bold;
  @apply bg-white text-slate-900;
}

*:focus {
  outline: none;
}
